<template>
  <div>
    <van-nav-bar
      title="查到结果"
      left-text="返回"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div v-if="isShow">
      <!-- <pdf class="pdf" ref="pdf" v-for="i in numPages" :key="i" :page="i" :src="pdfUrl">
      </pdf> -->
    </div>

    <div class="x-content" v-else>
      <van-image width="203px" height="159px" :src="noData" class="x-img" />
      <div class="x-msg">没有查到结果<br />请检查保单号或手机号是否错误</div>
    </div>
  </div>
</template>
<script>
import noData from "../assets/no-data.png";
import pdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import { urlParse } from "../util/publicFun";

export default {
  components: {
    // pdf,
  },
  data() {
    return {
      noData,
      pdfUrl: "",
      isShow: true,
      numPages: 0,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    getNumPages() {
      // 传参 CMapReaderFactory
      let loadingTask = pdf.createLoadingTask({ url: this.pdfUrl, CMapReaderFactory });
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf 加载失败", err);
        });
    },
  },

  mounted() {
    document.title = "查询结果";
    if (!this.$route.query.policyUrl) {
      this.isShow = false;
      return;
    }
    this.isShow = true;
    // let par = urlParse(this.$route.query.policyUrl);
    // this.$api.getFileStream(par).then((res) => {
    //   let blob = new Blob([res]);
    //   let href = window.URL.createObjectURL(blob); //创建下载的链接
    //   // window.location.href = href;
    //   // this.pdfUrl = href;

    //   // this.getNumPages();
    // });
  },
};
</script>
<style lang="less" scoped>
.x-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: white;

  .x-img {
    margin-top: 70px;
  }

  .x-msg {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #808080;
    text-align: center;
  }
}
</style>
